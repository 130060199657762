<template>
  <main>
    <div class="status_wrapper">
      <div class="achats">
        <div>L'opération débute le 15 Septembre 2022</div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Wait",
};
</script>